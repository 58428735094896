<template>
  <span class="countdown-timer">
    {{ remainingTime }}
  </span>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {

  data() {
    return {
      targetTime: moment().add(10, 'seconds'),
      remainingTime: '00:00:00',
      intervalId: null,
    }
  },
  computed: {
    ...mapGetters({ countdownEnded: 'countdown/isCountdownEnded' }),
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  mounted() {
    this.updateCountdown()
    this.intervalId = setInterval(this.updateCountdown, 1000)
  },
  methods: {
    ...mapActions('countdown', ['setCountdownEnded']),
    updateCountdown() {
      const now = moment()
      const diff = this.targetTime.diff(now)
      const duration = moment.duration(diff)

      if (duration.asMilliseconds() <= 0) {
        clearInterval(this.intervalId)
        this.setCountdownEnded()
      } else {
        const hours = String(duration.hours()).padStart(2, '0')
        const minutes = String(duration.minutes()).padStart(2, '0')
        const seconds = String(duration.seconds()).padStart(2, '0')

        this.remainingTime = ` ${seconds} : ${minutes} : ${hours} `
      }
    },
  },

}
</script>

  <style>
  .countdown-timer {
    direction: ltr;
    color: #000;
margin: 0px 4px;
font-family: Cairo;
font-size: 24px;
font-style: normal;
font-weight: 700;
  }
  </style>
